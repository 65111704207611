import {
  Alert,
  AppBar,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import RoleSelect from "src/components/Select/RoleSelect";
import {
  AddRoleToUser,
  CreateRole,
  GetAdmins,
  UpdateRole,
} from "src/services/api";
import { pageContainer } from "src/utils/customStyles";

const RoleOperations = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [value, setValue] = useState(
    Number(localStorage.getItem("tabValue")) || 0
  );
  const [roleNameAdd, setRoleNameAdd] = useState("");
  const [roleName, setRoleName] = useState("");
  const [admins, setAdmins] = useState("");
  const [newName, setNewName] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [roleNames, setRoleNames] = useState([]);
  const [createName, setCreateName] = useState("");
  const [userId, setUserId] = useState(location.state?.userId || "");
  const [roleNameUpdate, setRoleNameUpdate] = useState("");
  const [person, setPerson] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [roleSelectKey, setRoleSelectKey] = useState(0);
  const handleChangeRoleNameUpdate = (event) => {
    const selectedRole = roleNames.find(
      (role) => role.Id === event.target.value
    );
    setSelectedRole(selectedRole);
    setRoleNameUpdate(event.target.value);
  };

  // useEffect(() => {
  //   GetAdmins({ setData, setRoleNames, setAdmins });

  //   console.log(admins);
  // }, [roleName, roleSelectKey, newName, data]);

  useEffect(() => {
    const getadmin = async () => {
      try {
        const response = await GetAdmins();
        console.log(response);
        setAdmins(response.Data.adminUsers);
        setRoleNames(response.Data.adminRoles);
      } catch (error) {
        console.error("Hata oluştu:", error);
      }
    };
    getadmin();
  }, [roleName, roleSelectKey, newName]);
  const handleChange = (newValue) => {
    setValue(newValue);
    localStorage.setItem("tabValue", newValue);
  };
  useEffect(() => {
    return () => {
      localStorage.removeItem("tabValue");
    };
  }, []);
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleChangeRoleNameAdd = (event) => {
    setRoleNameAdd(event.target.value);
  };
  const onChangePerson = (event) => {
    setPerson(event.target.value);
  };
  const getAdmin = async () => {
    try {
      const response = await GetAdmins();
      console.log(response);
      setAdmins(response.Data.adminUsers);
      setRoleNames(response.Data.adminRoles);
    } catch (error) {
      console.error("Hata oluştu:", error);
    }
  };
  const onClickUpdateRole = async () => {
    if (selectedRole.Name !== "" && newName !== "") {
      try {
        setOpen(true);
        const response = await UpdateRole({
          RoleId: selectedRole.Id,
          RoleName: selectedRole.Name,
          NewRoleName: newName,
        });
        console.log(response);
        response.Success == false
          ? setAlertStatus(false)
          : setAlertStatus(true);
        setAlertMessage(response.Message);
        setOpenAlert(true);

        if (response.Success) {
          setRoleNameUpdate("");
          setNewName("");
          await getAdmin();
        }
      } catch (error) {
        setAlertMessage(error.Message);
        setAlertStatus(false);
        setOpenAlert(true);
        console.error("Hata oluştu:", error);
      } finally {
        setTimeout(() => {
          setOpenAlert(false);
          setOpen(false);
        }, 1000);
      }
    } else {
      setAlertMessage("Boş alan bırakılamaz!");
      setAlertStatus(false);
      setOpenAlert(true);
    }
  };
  const onClickCreateRole = async () => {
    if (createName !== "") {
      try {
        setOpen(true);
        const response = await CreateRole({
          RoleName: createName,
        });
        console.log(response);
        response.Success == false
          ? setAlertStatus(false)
          : setAlertStatus(true);
        setAlertMessage(response.Message);
        setOpenAlert(true);

        if (response.Success) {
          setCreateName("");
        }
      } catch (error) {
        setAlertMessage(error.Message);
        setAlertStatus(false);
        setOpenAlert(true);
        console.error("Hata oluştu:", error);
      } finally {
        setTimeout(() => {
          setOpenAlert(false);
          setOpen(false);
        }, 1000);
      }
    } else {
      setAlertMessage("Boş alan bırakılamaz!");
      setAlertStatus(false);
      setOpenAlert(true);
    }
  };
  const onClickAddRole = async () => {
    if (userId !== "" && roleNameAdd !== "") {
      try {
        setOpen(true);
        const response = await AddRoleToUser({
          UserId: userId,
          RoleName: roleNameAdd,
        });
        console.log(response);
        response.Success == false
          ? setAlertStatus(false)
          : setAlertStatus(true);
        setAlertMessage(response.Message);
        setOpenAlert(true);

        if (response.Success) {
          setCreateName("");
        }
      } catch (error) {
        setAlertMessage(error.Message);
        setAlertStatus(false);
        setOpenAlert(true);
        console.error("Hata oluştu:", error);
      } finally {
        setTimeout(() => {
          setOpenAlert(false);
          setOpen(false);
        }, 1000);
      }
    } else {
      setAlertMessage("Boş alan bırakılamaz!");
      setAlertStatus(false);
      setOpenAlert(true);
    }
  };
  function AddRole() {
    return (
      <Card
        sx={{
          p: 3,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: { md: "30rem", xs: "20rem" },
          margin: "0 auto",
          marginTop: 5,
          minWidth: "20rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Kullanıcıya Rol Ekle
        </Typography>
        <Divider
          sx={{
            width: "10%",
            backgroundColor: "primary.goldtag",
            height: 3,
            mb: 3,
          }}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl>
            <InputLabel id="role-select-label">Kullanıcı seçiniz</InputLabel>
            <Select
              labelId="role-person-label"
              id="role-person"
              value={userId}
              label="Kullanıcı seçiniz"
              onChange={(event) => setUserId(event.target.value)}
            >
              {admins != [] &&
                admins.map((admin) => (
                  <MenuItem key={admin.Id} value={admin.Id}>
                    {admin.Email}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <RoleSelect
            label="Rol seçiniz"
            value={roleNameAdd}
            onChange={handleChangeRoleNameAdd}
            roles={roleNames}
            valueKey="Name"
          />
          <Button variant="contained" onClick={() => onClickAddRole()}>
            Ekle
          </Button>
        </Box>
      </Card>
    );
  }
  function CreateRolee() {
    return (
      <Card
        sx={{
          p: 3,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: { md: "30rem", xs: "20rem" },
          margin: "0 auto",
          marginTop: 5,
          minWidth: "20rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Rol Oluştur
        </Typography>
        <Divider
          sx={{
            width: "10%",
            backgroundColor: "primary.goldtag",
            height: 3,
            mb: 3,
          }}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Rol adı giriniz"
            id="RoleName"
            variant="outlined"
            value={createName}
            onChange={(e) => setCreateName(e.target.value)}
          />

          <Button variant="contained" onClick={() => onClickCreateRole()}>
            Oluştur
          </Button>
        </Box>
      </Card>
    );
  }
  function UpdateRolee() {
    return (
      <Card
        sx={{
          p: 3,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: { md: "30rem", xs: "20rem" },
          margin: "0 auto",
          marginTop: 5,
          minWidth: "20rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Rol Güncelle
        </Typography>
        <Divider
          sx={{
            width: "10%",
            backgroundColor: "primary.goldtag",
            height: 3,
            mb: 3,
          }}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <RoleSelect
            key={roleSelectKey}
            label="Değiştirmek istediğiniz rolü seçiniz"
            value={roleNameUpdate}
            onChange={handleChangeRoleNameUpdate}
            roles={roleNames}
            valueKey="Id"
          />
          <TextField
            label="Yeni rol adını giriniz"
            id="RoleName"
            variant="outlined"
            value={newName}
            onChange={(event) => setNewName(event.target.value)}
          />
          <Button variant="contained" onClick={() => onClickUpdateRole()}>
            Güncelle
          </Button>
        </Box>
      </Card>
    );
  }

  return (
    <Box sx={pageContainer}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
      <Typography
        variant="h3"
        sx={{
          letterSpacing: "3px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
          paddingLeft: "3rem",
        }}
      >
        Rol İşlemleri
        <Divider
          sx={{
            width: "100px",
            backgroundColor: "primary.goldtag",
            height: 2,
          }}
        />
      </Typography>
      <Box
        sx={{
          justifyContent: "space-evenly",
          display: "flex",
        }}
      >
        <Button
          key={"btn0" + value}
          sx={{
            backgroundColor: value === 0 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(0)}
          variant="contained"
          fullWidth
        >
          Kullanıcıya Rol Ekle
        </Button>
        <Button
          key={"btn1" + value}
          sx={{
            backgroundColor: value === 1 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(1)}
          variant="contained"
          fullWidth
        >
          Rol Oluştur
        </Button>
        <Button
          key={"btn2" + value}
          sx={{
            backgroundColor: value === 2 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(2)}
          variant="contained"
          fullWidth
        >
          Rolü Güncelle
        </Button>
      </Box>
      {value == 0 && AddRole()}
      {value == 1 && CreateRolee()}

      {value == 2 && UpdateRolee()}
    </Box>
  );
};

export default RoleOperations;
