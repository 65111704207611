import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TransactionTypeSelect from "src/components/Select/TransactionTypeSelect";
import VendorSelect from "src/components/Select/VendorSelect";
import { CancelTransactionByGoldtag } from "src/services/api";
import { pageContainer } from "src/utils/customStyles";

const CancelTransaction = () => {
  const [vendor, setVendor] = useState("");
  const [errorVendor, setErrorVendor] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});

  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);

  const [transactionId, setTransactionId] = useState("");

  const [typeOfTransaction, setTypeOfTransaction] = useState("");
  const [errorType, setErrorType] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(false);

  const isCanceledTransaction = async () => {
    try {
      setLoading(true);
      const response = await CancelTransactionByGoldtag({
        VendorSuffix: vendor,
        TransactionId: Number(transactionId),
        TypeOfTransaction: typeOfTransaction,
      });
      if (response && response.Success) {
        setAlertMessage(response.Message);
        setAlertStatus(true);
        setOpenAlert(true);
        setResponseSuccess(true);
      } else {
        setAlertMessage(response.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setLoading(false);
      }, 2000);
      setTimeout(() => {
        setResponseSuccess(false);
      }, 4000);
      console.log("ID : " + transactionId);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  async function clearAll() {
    await setVendor("");
    await setTransactionId("");
    await setTypeOfTransaction("");
    // setResponseSuccess(false);
  }
  useEffect(() => {
    if (responseSuccess == true) {
      clearAll();
    }
  }, [responseSuccess]);

  return (
    <Box sx={pageContainer}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
      <Typography
        variant="h3"
        sx={{
          letterSpacing: "3px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
          paddingLeft: "3rem",
        }}
      >
        İptal İşlemleri
        <Divider
          sx={{
            width: "100px",
            backgroundColor: "primary.goldtag",
            height: 2,
          }}
        />
      </Typography>
      <Card
        sx={{
          p: 3,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: { md: "30rem", xs: "20rem" },
          margin: "0 auto",
          marginTop: 5,
          minWidth: "20rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          İptal İşlemleri
        </Typography>
        <Divider
          sx={{
            width: "10%",
            backgroundColor: "primary.goldtag",
            height: 3,
            mb: 3,
          }}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {/* VENDOR FORM CONTROL */}
          <VendorSelect
            vendor={vendor}
            setVendor={setVendor}
            errorVendor={errorVendor}
            setErrorVendor={setErrorVendor}
            setVendorInfo={setVendorInfo}
          />
          <TransactionTypeSelect
            type={typeOfTransaction}
            setType={setTypeOfTransaction}
            errorType={errorType}
            setErrorType={setErrorType}
          />
          <TextField
            id="outlined-basic"
            label="İşlem ID Giriniz"
            variant="outlined"
            type="number"
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
          />

          <Button variant="contained" onClick={() => isCanceledTransaction()}>
            İptal Et
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default CancelTransaction;
